import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { AllRulesQuery } from "../../../graphql-types";

const RulesIndex: React.FC = () => {
  const queryResult = useStaticQuery<AllRulesQuery>(pageQuery);

  useEffect(() => {
    if (!queryResult?.allMarkdownRemark?.edges?.length) return;

    const rules = queryResult.allMarkdownRemark.edges;

    // Pick a random rule
    const rule = rules[Math.floor(Math.random() * rules.length)];
    if (rule?.node?.frontmatter?.number)
      navigate(`/rules/${rule.node.frontmatter.number}`);
  }, [queryResult]);

  return <Layout>&nbsp;</Layout>;
};

export default RulesIndex;

export const pageQuery = graphql`
  query AllRules {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "rule-page" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            number
          }
        }
      }
    }
  }
`;
